@media (max-width: 1199px) {
    .sidebar-wrp {
        z-index: 100;
        transition: margin-left 0.3s ease-in-out;
        box-shadow: 3px 0 16px rgba(0, 0, 0, 0.7);
    }

    .sidebar-wrp.expand-mobile {
        margin-left: 0;
        /* border-right: 1px solid #666; */
    }
}