.selected {
  border: 1px solid #008000;
  background: #00a300 !important;
  transition: all 0.3s ease;
}

.notes-container>div {
  padding: 4px;
}

.notes-container a {
  text-decoration: underline;
}

.notes-container>div:nth-child(6n+3),
.notes-container>div:nth-child(6n+1),
.notes-container>div:nth-child(6n+2) {
  background-color: #0b1437;
}