@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: white;
}

th div {
  text-transform: capitalize;
  display: block;
}

.sigCanvas {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-color: #0b1437;
}

.retryButton {
  width: 15px;
  position: relative;
  filter: invert(100%);
}

a.active .activeMarker {
  background: var(--chakra-colors-brand-400);
  ;
}

.category a {
  display: block;
}

.category a.parent {
  margin-left: 0;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: inherit;
}

::-webkit-scrollbar-thumb {
  background-color: #f9fafb;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.user-box {
  padding: 15px;
  margin: 0 !important
}


.sidebar-wrp .sub .menu-icon {
  margin-left: 2rem;
}

.icon-rounded {
  border: 1px solid;
  border-radius: 4rem;
  transition: transform 0.3s;

}

.icon-rotate-180 {
  transform: rotate(-180deg);
}